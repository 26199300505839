<template>
  <div class="container-fluid pt-3">
    <Modal @close="toggleModal" :modalActive="modalActive">
      <template v-slot:header>{{ t("category.edit") }} </template>
      <template v-slot:body>
        <label for="category_search_filter" class="form-label">Liste filtern</label>
        <input type="text" class="form-control mb-3" id="category_search_filter" v-model="categorySearchFilter" />
        <template v-for="category in currentCategories" :key="category.id">
          <div v-if="isInCategorySearchFilter(category.category)" class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              v-model="category.hasCategory"
              :id="category.id"
            />
            <label class="form-check-label" :for="'category_' + category.id">{{ category.category }}</label>
          </div>
        </template>
      </template>
      <template v-slot:footer>
        <button @click="resetCategories" type="button" class="btn btn-secondary">zurücksetzen</button>
        <button @click="toggleModal" type="button" class="btn btn-primary">Änderungen Übernehmen</button>
      </template>
    </Modal>

    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-3">{{ t("address.address") }} {{ t("general.create") }}</h1>
        </div>
      </div>
      <!-- edit -->
      <div class="row row-cols-auto g-3 align-items-center mt-2">
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="address_is_person"
              v-model="address.is_person"
              true-value="1"
              false-value="0"
            />
            <label class="form-check-label" for="address_is_person">{{ t("address.person") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="address_is_company"
              v-model="address.is_company"
              true-value="1"
              false-value="0"
            />
            <label class="form-check-label" for="address_is_company">{{ t("address.company") }}</label>
          </div>
        </div>
        <div class="col">
          <div class="row row-cols-auto g-3 align-items-center">
            <label for="address_valuation" class="col col-form-label">{{ t("address.valuation") }}</label>
            <div class="col-sm-5 col-md-6 pt-2">
              <input
                type="range"
                v-model="address.valuation"
                class="form-range"
                min="1"
                max="7"
                step="1"
                id="address_valuation"
              />
            </div>
            <div class="col">
              <span class="badge bg-secondary" v-text="address.valuation ? address.valuation : '-'"></span>
            </div>
          </div>
        </div>
        <div class="col">
          <template v-for="category in currentCategories" :key="category.id">
            <div v-if="category.hasCategory" class="d-inline-block me-1 mb-1 pe-3 badge rounded-pill bg-primary">
              <svg
                @click="deleteCategory(category.id)"
                class="bi ms-1 cursor-pointer"
                width="20"
                height="20"
                fill="currentColor"
              >
                <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
              </svg>
              {{ category.category }}
            </div>
          </template>
        </div>
        <div class="col">
          <button
            @click="toggleModal"
            title="Edit Categories"
            type="button"
            class="btn btn-success d-block btn-icon mt-2 mt-md-0"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#pencil"></use>
            </svg>
          </button>
        </div>
      </div>
      <div class="g-3 row">
        <div class="col-md-12">
          <label for="address_name" class="form-label">{{ t("address.name") }}</label>
          <input type="text" class="form-control" id="address_name" v-model="address.name" />
        </div>
        <div class="col-md-2">
          <label for="address_salutation" class="form-label">{{ t("address.salutation") }}</label>
          <input type="text" class="form-control" id="address_salutation" v-model="address.salutation" />
        </div>
        <div class="col-md-5">
          <label for="address_forename" class="form-label">{{ t("address.forename") }}</label>
          <input type="text" class="form-control" id="address_forename" v-model="address.forename" />
        </div>
        <div class="col-md-5">
          <label for="address_surname" class="form-label">{{ t("address.surname") }}</label>
          <input type="text" class="form-control" id="address_surname" v-model="address.surname" />
        </div>
        <div class="col-md-6">
          <label for="address_email" class="form-label">
            {{ t("address.email") }}
            <a :href="'mailto:' + address.email">
              <svg class="bi ms-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#envelope-fill"></use>
              </svg>
            </a>
          </label>
          <input type="email" class="form-control" id="address_email" v-model="address.email" />
        </div>
        <div class="col-md-6">
          <label for="address_website" class="form-label">
            {{ t("address.website") }}
            <a :href="website.startsWith('http') ? website : 'https://' + website" target="_blank">
              <svg class="bi ms-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#arrow-right-circle-fill"></use>
              </svg>
            </a>
          </label>
          <input type="text" class="form-control" id="address_website" v-model="address.website" />
        </div>
        <div class="col-md-6">
          <label for="address_description" class="form-label">{{ t("address.description") }}</label>
          <input type="text" class="form-control" id="address_description" v-model="address.description" />
        </div>
        <div class="col-md-6">
          <label for="address_vat" class="form-label">{{ t("address.vat") }}</label>
          <input type="text" class="form-control" id="address_vat" v-model="address.vat" />
        </div>
        <div class="col-md-12">
          <label for="address_comment" class="form-label">{{ t("address.comment") }}</label>
          <textarea
            type="text"
            class="form-control"
            id="address_comment"
            rows="10"
            v-model="address.comment"
          ></textarea>
        </div>
        <!-- Address -->
        <div class="col-sm-4 col-lg-2">
          <label for="address_street" class="form-label">{{ t("address.street") }}</label>
          <input type="text" class="form-control" id="address_street" v-model="address.street" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_plz" class="form-label">{{ t("address.plz") }}</label>
          <input type="text" class="form-control" id="address_plz" v-model="address.plz" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_city" class="form-label">{{ t("address.city") }}</label>
          <input type="text" class="form-control" id="address_city" v-model="address.city" />
        </div>
        <div class="col-sm-4 col-lg-2">
          <label for="address_country" class="form-label">{{ t("address.country") }}</label>
          <select
            class="form-select"
            id="address_country"
            :aria-label="t('address.country')"
            v-model="address.country_id"
          >
            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
          </select>
        </div>
        <div class="col-sm-4 col-xxl-2">
          <label for="address_postbox" class="form-label">{{ t("address.postbox") }}</label>
          <input type="text" class="form-control" id="address_postbox" v-model="address.postcode" />
        </div>
        <div class="col-sm-4 col-xxl-2">
          <label for="address_postcode_pobox" class="form-label"
            >{{ t("address.plz") }} {{ t("address.postbox") }}</label
          >
          <input type="text" class="form-control" id="address_postcode_pobox" v-model="address.postcode_pobox" />
        </div>
        <!-- phone -->
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel_int_area_code" class="form-label">{{ t("address.tel_int_area_code") }}</label>
          <input type="text" class="form-control" id="address_tel_int_area_code" v-model="address.tel_int_area_code" />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel_city_area_code" class="form-label">{{ t("address.tel_city_area_code") }}</label>
          <input
            type="text"
            class="form-control"
            id="address_tel_city_area_code"
            v-model="address.tel_city_area_code"
          />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_tel" class="form-label">
            {{ t("address.tel") }}
            <a v-if="phone" :href="'tel:' + phone" class="text-decoration-none">
              <svg class="bi mx-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#telephone-fill"></use>
              </svg>
              <span class="fz-xs">{{ phone }}</span>
            </a>
          </label>
          <input type="text" class="form-control" id="address_tel" v-model="address.tel" />
        </div>
        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile_int_area_code" class="form-label">{{ t("address.mobile_int_area_code") }}</label>
          <input
            type="text"
            class="form-control"
            id="address_mobile_int_area_code"
            v-model="address.mobile_int_area_code"
          />
        </div>

        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile_area_code" class="form-label">{{ t("address.mobile_area_code") }}</label>
          <input type="text" class="form-control" id="address_mobile_area_code" v-model="address.mobile_area_code" />
        </div>

        <div class="col-md-4 col-xxl-2">
          <label for="address_mobile" class="form-label">
            {{ t("address.mobile") }}
            <a v-if="mobile" :href="'tel:' + mobile" class="text-decoration-none">
              <svg class="bi mx-1" width="24" height="24" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#phone-fill"></use>
              </svg>
              <span class="fz-xs">{{ mobile }}</span>
            </a>
          </label>
          <input type="text" class="form-control" id="address_mobile" v-model="address.mobile" />
        </div>
        <!-- editor -->
        <div class="w-100"></div>
        <div class="col-md-4 col-lg-2" v-if="activeUsers.length > 0">
          <label for="address_editor" class="form-label">{{ t("general.editor") }}</label>
          <select class="form-select" id="address_editor" :aria-label="t('general.editor')" v-model="address.editor">
            <option v-for="editor in activeUsers" :key="editor.id" :value="editor.id">{{ editor.name }}</option>
          </select>
        </div>
        <!-- save -->
        <div class="col-md-12">
          <button @click="save" type="button" class="btn d-block btn-success ps-1" :title="t('general.save')">
            <svg class="bi" width="24" height="24" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
            </svg>
            {{ t("general.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
// import { useRoute, useRouter } from "vue-router";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { useApiQuery } from "@/composables/useApiQueries";
import Modal from "@/components/Modal.vue";
// import AddressNavigation from "@/components/navigation/Address.vue";
import { useToast } from "vue-toastification";
// import { LMap, LTileLayer, LMarker, LControlLayers, LPopup } from "@vue-leaflet/vue-leaflet";
// import "leaflet/dist/leaflet.css";
export default {
  name: "AddressCreate",
  components: {
    Modal,
  },
  setup() {
    // const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const address = ref({
      is_person: 0,
      is_company: 0,
      name: null,
      salutation: null,
      description: null,
      surname: null,
      forename: null,
      email: null,
      street: null,
      plz: null,
      postcode: null,
      city: null,
      country_id: null,
      website: null,
      postcode_pobox: null,
      tel_int_area_code: null,
      tel_city_area_code: null,
      tel: null,
      mobile_area_code: null,
      mobile_int_area_code: null,
      mobile: null,
      fax: null,
      comment: null,
      valuation: null,
      editor: null,
      created_by: null,
      updated_by: null,
      created_at: null,
      updated_at: null,
      vat: null,
      lat: null,
      lon: null,
    });
    const addressCategories = ref([]);
    const { result: categories, query: getCategories } = useApiQuery({ url: "category", defaultVal: [] });
    const currentCategories = ref([]);
    const categorySearchFilter = ref("");
    // const isArtist = ref(false);
    const countries = ref({});
    const users = ref([]);
    const activeUsers = computed(() => {
      return users.value.filter((user) => user.status === "active");
    });
    const { t } = useI18n();
    const toast = useToast();
    const modalActive = ref(false);
    const currentUser = computed(() => store.getters.user);

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    async function getCountries() {
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.get(storeConfig.apiBaseUrl + "country");
        countries.value = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function getUsers() {
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.get(storeConfig.apiBaseUrl + "user");
        users.value = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function setGeocoding() {
      if (address.value.city && address.value.country) {
        try {
          let result = await axios.get("https://nominatim.openstreetmap.org", {
            params: {
              street: address.value.street,
              city: address.value.city,
              country: address.value.country[0].name,
              format: "json",
            },
            // important, otherwhise Authorization headers will be sent:
            transformRequest: [
              (data, headers) => {
                delete headers.common.Authorization;
                return data;
              },
            ],
          });
          if (result.data[0].lat.length) {
            address.value.lat = result.data[0].lat;
            address.value.lon = result.data[0].lon;
          }
        } catch (err) {
          // console.log(err.response);
        }
      }
    }

    function setCurrentCategories() {
      currentCategories.value = [];
      for (let i = 0; i < categories.value.length; i++) {
        let hasCategory = false;
        for (let j = 0; j < addressCategories.value.length; j++) {
          if (categories.value[i].id == addressCategories.value[j].id) {
            hasCategory = true;
            break;
          }
        }
        currentCategories.value.push({
          id: categories.value[i].id,
          category: categories.value[i].category,
          hasCategory: hasCategory,
        });
      }
    }

    function resetCategories() {
      currentCategories.value = [];
      setCurrentCategories();
      modalActive.value = false;
    }

    function isInCategorySearchFilter(category) {
      if (category.toLowerCase().includes(categorySearchFilter.value.toLowerCase())) {
        return true;
      }
      return false;
    }

    function deleteCategory(id) {
      currentCategories.value = currentCategories.value.filter((category) => category.id != id);
    }

    async function getAll() {
      await Promise.all([getCountries(), getUsers(), getCategories()]);
      setCurrentCategories();
    }

    const phone = computed(() => {
      let phone = "";
      if (address.value.tel) {
        if (address.value.tel_int_area_code) {
          phone = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.tel_city_area_code) {
            phone += address.value.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_city_area_code) {
          phone = address.value.tel_city_area_code.replace(/\s/g, "");
        }
        phone += address.value.tel.replace(/\s/g, "");
      }
      return phone;
    });

    const mobile = computed(() => {
      let mobile = "";
      if (address.value.mobile) {
        if (address.value.mobile_int_area_code) {
          mobile = "+" + address.value.mobile_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.mobile_area_code) {
            mobile += address.value.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_int_area_code) {
          mobile = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.mobile_area_code) {
            mobile += address.value.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.mobile_area_code) {
          mobile = address.value.mobile_area_code.replace(/\s/g, "");
        }
        mobile += address.value.mobile.replace(/\s/g, "");
      }
      return mobile;
    });

    const fax = computed(() => {
      let fax = "";
      if (address.value.fax) {
        if (address.value.tel_int_area_code) {
          fax = "+" + address.value.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
          if (address.value.tel_city_area_code) {
            fax += address.value.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
          }
        } else if (address.value.tel_city_area_code) {
          fax = address.value.tel_city_area_code.replace(/\s/g, "");
        }
        fax += address.value.fax.replace(/\s/g, "");
      }
      return fax;
    });

    async function save() {
      await setGeocoding();
      const data = {
        is_person: address.value.is_person,
        is_company: address.value.is_company,
        name: address.value.name,
        salutation: address.value.salutation,
        description: address.value.description,
        surname: address.value.surname,
        forename: address.value.forename,
        email: address.value.email,
        street: address.value.street,
        plz: address.value.plz,
        postcode: address.value.postcode,
        city: address.value.city,
        country_id: address.value.country_id,
        website: address.value.website,
        postcode_pobox: address.value.postcode_pobox,
        tel_int_area_code: address.value.tel_int_area_code,
        tel_city_area_code: address.value.tel_city_area_code,
        tel: address.value.tel,
        mobile_int_area_code: address.value.mobile_int_area_code,
        mobile_area_code: address.value.mobile_area_code,
        mobile: address.value.mobile,
        fax: address.value.fax,
        comment: address.value.comment,
        valuation: address.value.valuation,
        editor: address.value.editor,
        updated_by: currentUser.value.id,
        vat: address.value.vat,
        lat: address.value.lat,
        lon: address.value.lon,
        categories: currentCategories.value
          .filter((category) => category.hasCategory == true)
          .map((category) => category.id),
      };
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.post(storeConfig.apiBaseUrl + "addresses", data);
        // console.log(result);
        if (result.data.id) {
          toast.success(t("address.address") + " " + t("general.successfully") + " " + t("general.added") + ".");
          router.push("/addresses/edit/" + result.data.id);
        }
        // getAll();
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    getAll();

    return {
      address,
      countries,
      addressCategories,
      phone,
      mobile,
      fax,
      categories,
      currentCategories,
      categorySearchFilter,
      isInCategorySearchFilter,
      resetCategories,
      users,
      activeUsers,
      t,
      website: computed(() =>
        address.value.website
          ? address.value.website.startsWith("http")
            ? address.value.website
            : "https://" + address.value.website
          : ""
      ),
      save,
      modalActive,
      toggleModal,
      deleteCategory,
    };
  },
};
</script>
